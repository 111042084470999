
    export default {
        data () {
            return {
                types: {}
            };
        },
        watch: {
            '$route.query': '$fetch'
        },
        async fetch () {

            // Set claim types
            this.types = await this.$content(this.$i18n.localeProperties.code.substr(0, 2))
                                   .where({ 'type': 'claim_type' })
                                   .fetch();
        }
    };


import axios from 'axios';
import PromoContent from '../components/PromoContent.vue';
import Services from '../components/Services.vue';
import HeaderFull from '../components/HeaderFull.vue';
import FooterFull from '../components/FooterFull.vue';
import ContentColumns from '../components/ContentColumns.vue';
// import {required, minLength, maxLength, exact, email, numeric, alphaNum} from 'vuelidate/lib/validators';

export default {
  components: {
    PromoContent,
    HeaderFull,
    FooterFull,
    Services,
    ContentColumns
  },
  nuxtI18n: {
    paths: {
      'it': '/accesso',
      'es': '/accede',
      'ro': '/logare',
      'en': '/login',
      'ru': '/login',
      'pt': '/acesso',
      'hu': '/bejelentkezes',
      'pl': '/logowanie',
      "sq": "/hyrje",
      "bg": "/vhod",
      "de": "/anmeldung",
      "cs": "/prihlaseni",
      "fr": "/connexion",
      "lt": "/prisijungimas",
      "mk": "/login",
      "nl": "/inloggen",
      "no": "/logg-inn",
      "sl": "/prijava",
      "sr": "/prijava",
      "sv": "/inloggning",
      "el": "/eisodos",
      "hy": "/login",
      "da": "/login",
      "tr": "/giris",
      "et": "/sisselogimine",
      "sk": "/prihlasenie",
    }
  },
  data() {
    return {
      error: false,
      error_message: '',
      form: {},
      type: {},
      visible: false,
      loading: true,
      page: {
        content: {
          title: '',
          content: '',
          title_second: '',
          content_second: ''
        }
      },
      submitDisabled: false
    };
  },
  methods: {
    submit() {
      this.error = false;
      this.$refs.observer.validate()
          .then((result) => {
            if (!result) return;
            this.submitDisabled = true;
            this.$api.claims.verify(this.form.email, this.form.internal_code)
                .then((res) => {
                  this.submitDisabled = false;

                  if (res.data.status === 'error') {
                    this.error = this.$t('login.' + res.data.message);
                  } else {
                    var url = this.localeRoute({
                      name: 'status-internalcode',
                      params: {internalcode: res.data.claim.code}
                    }).fullPath;

                    this.$router.push({
                      path: url
                    });
                  }
                })
                .catch((error) => {
                  this.error = true;
                  this.error_message = error.response.data.message;
                  this.submitDisabled = false;
                });
          });
    }
  },
  watch: {
    '$route.query': '$fetch'
  },
  created() {

  },
  async fetch() {
    const tmp = await this.$content(this.$i18n.localeProperties.code.substr(0, 2) + '/contents')
        .where({'code': 'login'})
        .fetch();
    this.page = tmp[0];
    this.$store.commit('seo/setTitle', this.page.seo.title);
    this.$store.commit('seo/setDescription', this.page.seo.description);
    this.$store.commit('seo/setKeywords', this.page.seo.keywords);
    this.$store.commit('seo/setImage', this.page.image + '?format=webp');
    this.$store.commit('seo/setCanonical', this.localeRoute({
      name: 'login'
    }).fullPath);

    this.loading = false;
  }
};

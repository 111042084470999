
import {extend} from 'vee-validate';
import {parsePhoneNumberFromString} from 'libphonenumber-js';

export default {
  props: {
    name: {
      type: String,
      default: ' '
    },
    rules: {
      type: String,
      default: ' '
    },
    message: {
      default: true
    },
    margin: {
      default: true
    }
  },
  created() {
    extend('phone', {
      validate: (value) => {
        if (!value || value === '') {
          return true;
        }
        const phoneNumber = parsePhoneNumberFromString(value, 'RO');
        return phoneNumber && phoneNumber.isValid();
      },
      message: this.$t('validation.phone_invalid'),
    });

    extend('validateIban', (value) => {
      return new Promise((resolve, reject) => {
        this.$api.paymentsHelper.verifyBankAccount(value)
            .then((res) => {
              resolve({
                'valid': true
              });
            })
            .catch(() => {
              resolve({
                'valid': false
              });
            });
      });
    });
    extend('validateTypeBankRevolut', (value) => !(value.toUpperCase().substring(0, 2) == 'RO' && value.toUpperCase().substring(4, 13) == 'BREL00055'));

    extend('numeric', {
      validate: (value) => {
        return !isNaN(value);
      },
      message: this.$t('validation.numeric'),
    });

    extend('min', {
      validate: (value, {length}) => {
        return value.length >= length;
      },
      params: ['length'],
      message: this.$t('validation.min'),
    });

    extend('max', {
      validate: (value, {length}) => {
        return value.length <= length;
      },
      params: ['length'],
      message: this.$t('validation.max'),
    });

    extend('emailNotRegistered', {
      params: ['email'],
      validate: async (value) => {
        try {
          const response = await this.$api.user.verifyUser(value);
          return !response.data.user;
        } catch (error) {
          return false;
        }
      },
      message: this.$t('validation.emailNotRegistered', {
        login: this.localePath({
          name: 'user-login'
        }),
        password: this.localePath({
          name: 'user-password'
        })
      }),
    });


    extend('claimId', (value) => {
      if (value) {
        return new Promise((resolve, reject) => {
          this.$api.claims.verifyClaimID(value)
              .then((res) => {
                resolve({
                  'valid': res.data.valid
                });
              })
              .catch(() => {
                resolve({
                  'valid': false
                });
              })
        })
      } else {
        return false;
      }
    });
  }
}

import { render, staticRenderFns } from "./login.vue?vue&type=template&id=2a4cb440"
import script from "./login.vue?vue&type=script&lang=js"
export * from "./login.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderFull: require('/code/current/components/HeaderFull.vue').default,Layout2Input: require('/code/current/components/Layout2/Layout2Input.vue').default,Layout2Validation: require('/code/current/components/Layout2/Layout2Validation.vue').default,FooterFull: require('/code/current/components/FooterFull.vue').default})
